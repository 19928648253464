import {consola as l} from "consola/basic";

export default function () {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = '//launcher-sa.spot.im/spot/sp_RRP0YMUq';
    script.setAttribute("data-spotim-module", "spotim-launcher");
    script.setAttribute("data-spotim-autorun", "false");

    script.addEventListener('load', function () {
        l.success('Loaded Openweb', script.src)
    });

    const node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(script, node);
}