export default function () {

    function appendCss(backgroundColor, backgroundImage980, backgroundImage1280) {

        let css = `<style> 
        #skin-layout {
          position: relative;
          cursor: pointer;
        }
        #skin-layout > {
            cursor: default;
        }
        #skin-layout::before {
            content: "";
            inset: 0;
            background-attachment: fixed;
            z-index: -1;
            position: fixed;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center top;
            background-color: ${backgroundColor};
            background-image: url(${backgroundImage1280});
        }
        
        #main{
          margin-top: 15%;
          margin-left: auto!important;
          margin-right: auto!important;
          background-color: white!important;
          width: 75%;
        }

        .masthead-container
        {
         display: none!important;
        }
        
        .adv{
         box-shadow: none!important;
        }
        
        /* On screens that are 1440px or less */
        @media screen and (max-width: 1440px) {
         #skin-layout::before{
           background-image: url(${backgroundImage980});
         }
         
         .layout-gap{
          padding: 0!important;
         }
         
         .md\\:-mx-layout-gap-desktop{
          margin: 0!important;
         }
        }
        </style>`;

        document.head.insertAdjacentHTML("afterbegin", css);
    }

    function clickLocation(url){
        window.location.href = url;
    }

    window.addEventListener(
        "message",
        (event) => {
            if (
                window.innerWidth > 760 &&
                event.data.type === 'skin' &&
                event.data.clickUrl &&
                event.data.backgroundImage980 &&
                event.data.backgroundImage1280 &&
                event.data.backgroundColor

            ) {

                const clickUrl = event.data.clickUrl;

                appendCss(
                    event.data.backgroundColor,
                    event.data.backgroundImage980,
                    event.data.backgroundImage1280,
                );

                const skinLayoutElement = document.createElement("div");
                skinLayoutElement.setAttribute('id', 'skin-layout');

                skinLayoutElement.addEventListener("click", (event) => {
                    clickLocation(clickUrl);
                });

                document.body.addEventListener("click", (event) => {
                    if (event.target === document.body){
                        clickLocation(clickUrl);
                    }
                });

                document.body.addEventListener("mouseover", (event) => {
                    if (event.target === document.body){
                        document.body.classList.add('cursor-pointer');
                    }
                });

                document.body.addEventListener("mouseout", (event) => {
                    if (event.target === document.body){
                        document.body.classList.remove('cursor-pointer');
                    }
                });

                document.body.insertAdjacentElement("afterbegin", skinLayoutElement);

            }
        },
        false,
    );

/*
*
    (function () {
        const data = {
            type: 'skin',
            clickUrl: 'https://leghe.fantamaster.it/tool/',
            backgroundColor: 'transparent',
            backgroundImage980: 'https://tpc.googlesyndication.com/simgad/16546370964195588349',
            backgroundImage1280: 'https://tpc.googlesyndication.com/simgad/4709211165991390573',
        };
        window.top.postMessage(data, '*');
    }());
*/
}