import {consola as l} from "consola/basic";

export default function () {

    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5G9VVDD');
    window.dispatchEvent(new Event("google-tagmanager-load"));


    // Tagmanager cm
    const tgmHead = document.getElementsByTagName("head")[0];
    const tgmScript = document.createElement("script");
    tgmScript.setAttribute("type", "text/javascript");
    tgmScript.async = true;
    tgmScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-DPWFDH5V2G");
    tgmScript.addEventListener('load', function () {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'wait_for_update': 1500
        });
        gtag('set', 'ads_data_redaction', true);
        gtag('set', 'url_passthrough', true);

        gtag('js', new Date());
        gtag('config', 'G-DPWFDH5V2G');
    });

    tgmHead.appendChild(tgmScript);

    l.info('Load google tag manager')
}
