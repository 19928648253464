import {consola as l} from "consola/basic";


export default function () {
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");

    script.setAttribute("type", "text/javascript");
    script.setAttribute("async", true);
    script.setAttribute("src", "//securepubads.g.doubleclick.net/tag/js/gpt.js");

    script.addEventListener('load', function () {
        l.success('Google gpt loaded', script.src)
    });

    head.appendChild(script);

}