import {consola as l} from "consola/basic";

export default function () {

    setTimeout(() => {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");

        script.setAttribute("type", "text/javascript");
        script.async = true;
        script.setAttribute("src", "//a.teads.tv/analytics/tag.js");
        head.appendChild(script);

        l.info('Load teads cookieless')

    }, 1000)

}