import {consola as l} from "consola/basic";
import device from './../device';

export default function () {
    let url = device.isMobile() ? '//ads.pubmatic.com/AdServer/js/pwt/156309/3626' : '//ads.pubmatic.com/AdServer/js/pwt/156309/3625';
    let urlUas = '//ads.pubmatic.com/AdServer/js/phoenix.js';

    const purl = window.location.href;
    // const url = '//ads.pubmatic.com/AdServer/js/pwt/156309/3626';
    let profileVersionId = '';
    if (purl.indexOf('pwtv=') > 0) {
        const regexp = /pwtv=(.*?)(&|$)/g;
        const matches = regexp.exec(purl);
        if (matches.length >= 2 && matches[1].length > 0) {
            profileVersionId = '/' + matches[1];
        }
    }
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = url + profileVersionId + '/pwt.js';

    script.addEventListener('load', function () {
        l.success('Loaded Pubmatic library', script.src)
    });

    const node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(script, node);

    /*
    * load pubmatic uas
    * */

    window.Phoenix = window.Phoenix || {};
    window.Phoenix.EQ = Phoenix.EQ || [];

    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.type = 'text/javascript';
    scriptElement.src = urlUas;
    scriptElement.addEventListener('load', function () {
        l.success('Loaded Pubmatic Uas library', scriptElement.src)
    });

    const nodeUas = document.getElementsByTagName('script')[0];
    nodeUas.parentNode.insertBefore(scriptElement, node);

}